<template>
    <router-view />
</template>

<script>
export default {
    mounted() {
        if (this.$route.name === 'admin-home') this.$redirect('admin-bookings');
    }
}
</script>
